<template>
  <section id="background-top" class="background-top-section">
    <svg class="display-it" viewBox="0 0 2025 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="57" width="293" height="200" fill="#ffebdb" />
      <rect x="1732" y="110" width="293" height="200" fill="#ffebdb" />
      <path
        d="M209 231C217.41 106.601 254.396 64.7272 304.196 53.1365L492.455 9.3202C518.959 3.1517 546.318 1.5353 573.364 4.5404L822.609 32.2344C836.105 33.7339 849.249 37.5132 861.48 43.4112L969.5 95.5 1117.82 143.735C1141.79 151.53 1166.84 155.5 1192.04 155.5H1344.5L1527 132 1703.49 111.253C1774.83 102.868 1837.5 158.606 1837.5 230.433H298Z"
        fill="#FFEBDB"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: "BackgroundTop",
}
</script>

<style lang="scss" scoped>
.display-it {
  display: block;
}
</style>
